import React, { useState,useEffect } from "react";
import {Accordion,AccordionSummary,AccordionDetails,Typography,Box,CircularProgress,Alert,Skeleton, Button} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { pink } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import {Images} from "./Images";
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import { StyledBadge } from "./styled";
import { UniUploadButton } from "./UniUploadButton";
import {StahniFotky,SmazFotku,NahrajFotky} from "../services/FileService";
import { useMsal} from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { NedostupnaSluzba,NeocekavanaChyba} from "./StavoveHlasky";
import {isMobile} from 'react-device-detect';
import Fotak2 from "./Fotak2";
import UniCircularProgressBar from "./UniCircularProgressBar";

const styles = {
    accorPopis:{
      fontWeight: 'bold', 
    },
    stavText:{
      lineHeight: '35px',
      fontWeight: 'bold',
      color: 'white',
      backgroundColor: '#8FD14F',
      borderRadius:'8px',
      pl:1,
      pr:1
    },
    nadpis:{
      width: '100px',
      backgroundColor: '#3C486B',
      color: 'white',
      p:"1px",
      borderRadius: '16px',
      mt:"-28px",
      mb:1
    },
    nadpisDoc:{
      width: '200px',
      backgroundColor: '#3C486B',
      color: 'white',
      p:"1px",
      borderRadius: '16px',
      mt:"-28px",
      mb:1
    },
    sekce:{
      flex:"50%",
      p:2,
      ml:1,
      mr:1,
      mt:1,
      mb:2,
      borderRadius: '16px',
      borderColor: '#3C486B',
    },
    uniokbadge:{
      "& .MuiBadge-badge": {
        color: 'white',
        backgroundColor: '#088443',
      }
    },
    uniproblembadge:{
      "& .MuiBadge-badge": {
        color: 'white',
        backgroundColor: '#a52b71',
      }
    }   
  };


export default function Fotografie(props) { 
    const { instance,accounts } = useMsal();
    let [fotos,setFotos] = useState([]);
    let [statusDownloadImage,setStatusDownloadImage] = useState(200);
    let [statusUploadImage,setStatusUploadImage] = useState(0);
    let [statusDeleteImage,setStatusDeleteImage] = useState(0);
    let [error,setError] = useState();
    let [uploadingImages,setUploadingImages] = useState(false);
    let [loading,setLoading] = useState(true);

    const request = {
        ...loginRequest,
        account: accounts[0]
      };
      
    const handleUploadedImages = (files) => {
        setUploadingImages(true);

        instance.acquireTokenSilent(request).then((response) => {
            NahrajFotky(response.accessToken,props.cislols,files).then(fotos => {
              setStatusUploadImage(fotos.status);              
              if (fotos.data && fotos.status === 200) setFotos(fotos.data);
              if (fotos.data && fotos.status > 200) setError(fotos.data); 
              setUploadingImages(false);  
          }); 
          }).catch((e) => {	  	
            instance.acquireTokenPopup(request).then((response) => {
                NahrajFotky(response.accessToken,props.cislols,files).then(fotos => {
                  setStatusUploadImage(fotos.status);              
                  if (fotos.data && fotos.status === 200) setFotos(fotos.data);
                  if (fotos.data && fotos.status > 200) setError(fotos.data); 
                  setUploadingImages(false);                    
                }); 
            });
          });
      }

    const handleDeleteImage = (soubor) => {
        instance.acquireTokenSilent(request).then((response) => {
            SmazFotku(response.accessToken,props.cislols,soubor).then(fotos => {
              setStatusDeleteImage(fotos.status);
              if (fotos.data && fotos.status === 200) setFotos(fotos.data);
              if (fotos.data && fotos.status > 200) setError(fotos.data); 
          }); 
          }).catch((e) => {	  	
            instance.acquireTokenPopup(request).then((response) => {
                SmazFotku(response.accessToken,props.cislols,soubor).then(fotos => {
                  setStatusDeleteImage(fotos.status);
                  if (fotos.data) setFotos(fotos.data);
                }); 
            });
          });
          setStatusUploadImage(0); 
      }      

      useEffect(() => {
        //console.log("useEffect: "+loading);
        setLoading(true);
        instance.acquireTokenSilent(request).then((response) => {
          StahniFotky(response.accessToken,props.cislols).then(fotos => {
          //console.log(fotos.data);
          setStatusDownloadImage(fotos.status);
          if (fotos.data && fotos.status === 200) setFotos(fotos.data);
          if (fotos.data && fotos.status > 200) setError(fotos.data); 
          setLoading(false);
        }); 
        }).catch((e) => {	  	
          instance.acquireTokenPopup(request).then((response) => {
                StahniFotky(response.accessToken,props.cislols).then(fotos => {
                setStatusDownloadImage(fotos.status);
                if (fotos.data && fotos.status === 200) setFotos(fotos.data);
                if (fotos.data && fotos.status > 200) setError(fotos.data); 
                setLoading(false);
              }); 
          });
        });
      },[]);

return (
<Accordion>
<AccordionSummary
  expandIcon={<ExpandMoreIcon />}
  aria-controls="panel3a-content"
  id="panel3a-header"
>
  <Typography sx={styles.accorPopis}>Fotografie</Typography>
  {loading ? 
  <Skeleton variant="rounded" width={35} height={35} />
:
  <Tooltip title="Fotografie">
  {fotos.length > 2 ?
    <StyledBadge sx={styles.uniokbadge} badgeContent={fotos.length}>
      <PhotoCameraOutlinedIcon sx={{mr:1,p:'2px',color:'white',bgcolor:'#8FD14F',fontSize:'35px',borderRadius:'8px'}} />   
    </StyledBadge>
      :
    <StyledBadge sx={styles.uniproblembadge} badgeContent={fotos.length} showZero>
      <PhotoCameraOutlinedIcon sx={{mr:1,p:'2px',color:'white',bgcolor:pink[500],fontSize:'35px',borderRadius:'8px'}} />
    </StyledBadge>
  }          
  </Tooltip>
}
</AccordionSummary>
<AccordionDetails> 
    <div>
      <div>{statusUploadImage === 200 ? <Alert severity="success">Fotografie byly úspěšně nahrány na server!</Alert>:<p></p>}</div>
      <div>{statusDownloadImage === -1 || statusUploadImage === -1 || statusDeleteImage === -1 ? <NedostupnaSluzba/>:<p></p>}</div>
      <div>{statusDownloadImage > 200 || statusUploadImage > 200 || statusDeleteImage > 200 ? <NeocekavanaChyba chyba={error} />:<p></p>}</div>

      {/* <Box sx={{display:"flex",alignItems:"center"}}>
          <Fotak typ={-1} handleUploadedFiles={handleUploadedImages}/>
          <UniUploadButton typ={-1} handleUploadedFiles={handleUploadedImages}/>          
          {uploadingImages ?<CircularProgress /> :<></>} 
        </Box> */}

        <Box sx={{display:"flex",alignItems:"center",mb:1}}>
          {isMobile ?
          <Fotak2 typ={-1} handleUploadedFiles={handleUploadedImages}/>:<></>
          }
          <UniUploadButton typ={-1} pdf={false} handleUploadedFiles={handleUploadedImages}/>          
          {uploadingImages ?<UniCircularProgressBar /> :<></>} 
        </Box>
      {fotos.length > 0  ?          
        <Images files={fotos} handleDeleteFile={handleDeleteImage} mazani={true} />
        :
        <div><i>Nejsou nahrány žádné fotografie.</i></div>}
      </div>
</AccordionDetails>
</Accordion>
);
}