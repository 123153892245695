import React,{useState} from 'react';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { Container,AppBar,Toolbar,Alert,IconButton,Typography,Avatar,Menu,MenuItem,Box} from '@mui/material';
import MenuBocni from './MenuBocni';
import MenuIcon from '@mui/icons-material/Menu';
import { BrowserRouter } from 'react-router-dom';
import {Link as RouterLink  } from 'react-router-dom';
import { Offline, Online } from "react-detect-offline";


export const PageLayout = (props) => {
    const { instance,accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated(); //ostra verze
    const [state, setState] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const name = accounts[0] && accounts[0].name;

    

    function handleLoginClick() {
      if (isAuthenticated) {
        instance.logoutRedirect({
          postLogoutRedirectUri: "/",
          mainWindowRedirectUri: "/" // redirects the top level app after logout
        });
        setAnchorEl(null);
      } else {
        instance.loginRedirect().catch(e => {
          console.log(e);
        });        
      }
    }
    
    function handleClick() {
      setState(true);
    }

    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    function nameToInitials(fullName) {
      const namesArray = fullName.trim().split(' ');
      if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
      else return `${namesArray[namesArray.length - 1].charAt(0)}${namesArray[0].charAt(0)}`;
    }

    return (
        <>  
        <BrowserRouter>
      <Container maxWidth="lg" disableGutters sx={{mt:0,bgcolor:"#003883"}}>
        <Offline>
          <Alert severity="warning">Nejste připojeni k internetu. Aplikaci WEBUNIKALK nelze použít.</Alert>
        </Offline>
        <AppBar elevation={0} position="static" sx={{bgcolor:"#003883"}}>
          <Toolbar>                 
            <Box component={RouterLink} sx={{display:"flex",textDecoration: "none"}} to="/">            
              <Typography variant="h5" sx={{textDecoration: "none",color:"white"}}>Web</Typography>
              <Typography variant="h5" sx={{textDecoration: "none",color:"#dd9234"}}>UniKalk</Typography>            
            </Box>
            <Box sx={{ flexGrow: 2}}></Box>
            {isAuthenticated ? 
            <IconButton
                
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                
                <Avatar sx={{ bgcolor: "#F29400" }}>{nameToInitials(name)}</Avatar>
              </IconButton> : <></>}
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem divider component={RouterLink} to="/nastaveni" onClick={handleClose}>Nastavení</MenuItem>
                <MenuItem onClick={handleLoginClick}>Odhlásit se</MenuItem>
              </Menu>
            {/* {isAuthenticated ? <Button color="inherit" sx={{ mr: 2 }} onClick={handleLoginClick}>Odhlásit se</Button> : <Button color="inherit" sx={{ mr: 2 }} onClick={handleLoginClick}>Přihlásit se</Button>} */}
          {isAuthenticated ?                                     
      <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={handleClick}>
            <MenuIcon />
          </IconButton> : <></>}
          </Toolbar> 
          <MenuBocni state={state} setState={setState} />
        </AppBar>    
        
        {props.children}

      </Container>  

    </BrowserRouter> 
    </>
    );
};